<template>
  <div class="main-content">
    <el-form :model="dataForm" ref="dataForm" :rules="currentRules">
      <AssociationScroll>
        <AssociationScrollView title="报案信息" name="0">
          <div class="layout-main" style="margin-top: 16px">
            <div class="row-main">
              <div class="col-main">
                <div class="col-title">报案人</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.reportCasePersonName"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'input',
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
              <div class="col-main">
                <div class="col-title">出险时间</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.dangerDate"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'datePicker',
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
              <div class="col-main">
                <div class="col-title">报案时间</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.reportCaseTime"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'datePicker',
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>

              <div class="col-main">
                <div class="col-title">事故类型</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      @returnVal="returnValOption($event, dataForm.caseInfo)"
                      v-model="dataForm.caseInfo.accidentCauseValue"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'select',
                        width: '100%',
                        option: dictList.NewEnergyAccidentReason,
                        modelTextKey: 'accidentCause',
                      }"
                      :optionConfig="{
                        value: 'dictKey',
                        label: 'dictValue',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="row-main">
              <div class="col-main">
                <div class="col-title">报案人电话</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.reportCasePhone"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'input',
                        isNum: true,
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
              <div class="col-main">
                <div class="col-title">案件跟进人</div>
                <div class="col-form">
                  <el-form-item>
                    <!-- <TextInput
                      v-model="dataForm.caseInfo.caseFollower"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'input',
                        width: '100%',
                      }"
                    >
                    </TextInput> -->
                    <TextInput
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'slot',
                        width: '100%',
                      }"
                      v-model="dataForm.caseInfo.caseFollower"
                    >
                      <EmployeeSelect
                        v-if="controlEdit['5']"
                        v-model="dataForm.caseInfo.caseFollowerId"
                        @changeChecked="changeCaseFollower"
                        placeholder="请选择"
                      />
                      <span v-else>{{ dataForm.caseInfo.caseFollower }}</span>
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
              <div class="col-main">
                <div class="col-title">案件跟进人电话</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.caseFollowerPhone"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'input',
                        isNum: true,
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>

              <div class="col-main">
                <div class="col-title">是否有人伤</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.isPersonalInjury"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'select',
                        width: '100%',
                        option: dictList.yesNoNum,
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="row-main">
              <div class="col-main">
                <div class="col-title">出险地点</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.dangerDetailAddress"
                      :isText="!controlEdit['5']"
                      :config="{
                        modelKey: 'dangerDetailAddress',
                        type: 'location',
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
              <div class="col-main">
                <div class="col-title">案件备注</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.dangerRemark"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'input',
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="保险信息" name="1">
          <div class="layout-main" style="margin-top: 16px">
            <div class="row-main">
              <div class="col-main">
                <div class="col-title">保险公司</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.policyCompany"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'input',
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
              <div class="col-main">
                <div class="col-title">保单号</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.policyNo"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'input',
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
              <div class="col-main">
                <div class="col-title">保费</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.premium"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'input',
                        isNum: true,
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>

              <div class="col-main">
                <div class="col-title">报案号</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.realCaseNo"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'input',
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="row-main">
              <div class="col-main">
                <div class="col-title">起始时间</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.policyStartDate"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'datePicker',
                        width: '100%',
                        dateFormat: 'YYYY-MM-DD 00:00:00',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
              <div class="col-main">
                <div class="col-title">结束时间</div>
                <div class="col-form">
                  <el-form-item>
                    <TextInput
                      v-model="dataForm.caseInfo.policyEndDate"
                      :isText="!controlEdit['5']"
                      :config="{
                        type: 'datePicker',
                        width: '100%',
                        dateFormat: 'YYYY-MM-DD 23:59:59',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <el-form-item>
            <div style="margin-bottom: 10px">电子保单：</div>
            <BoxUpload
              :count="0"
              :disabled="!controlEdit['5']"
              attachmentType="XNYLPDZBD"
              v-model="dataForm.insClaimsAttachmentList"
            ></BoxUpload>
          </el-form-item>
        </AssociationScrollView>
        <AssociationScrollView title="标的信息" name="2">
          <template v-if="controlEdit['6']" slot="head-right">
            <el-button
              @click="addTriCardSubject"
              icon="el-icon-circle-plus-outline"
              class="base-btn green-btn"
              >添加三者车辆</el-button
            >
          </template>
          <div class="subject">
            <div
              style="margin-top: 16px"
              class="subject-item"
              v-for="(subject, index) in dataForm.subjectInfoList"
            >
              <div class="layout-main">
                <div class="row-main">
                  <div class="col-main">
                    <div class="col-title">车牌号码</div>
                    <div class="col-form">
                      <el-form-item>
                        <TextInput
                          v-model="subject.licensePlate"
                          :isText="!controlEdit['6']"
                          :config="{
                            type: 'input',
                            width: '100%',
                          }"
                        >
                        </TextInput>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-main">
                    <div class="col-title">车辆类型</div>
                    <div class="col-form">
                      <el-form-item>
                        <TextInput
                          @returnVal="returnValOption($event, subject)"
                          v-model="subject.vehicleTypeValue"
                          :isText="!controlEdit['6']"
                          :config="{
                            type: 'select',
                            width: '100%',
                            option: dictList.NewEnergyVehicleType,
                            modelTextKey: 'vehicleType',
                          }"
                          :optionConfig="{
                            value: 'dictKey',
                            label: 'dictValue',
                          }"
                        >
                        </TextInput>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-main">
                    <div class="col-title">车辆识别号</div>
                    <div class="col-form">
                      <el-form-item>
                        <TextInput
                          v-model="subject.vin"
                          :isText="!controlEdit['6']"
                          :config="{
                            type: 'input',
                            width: '100%',
                          }"
                        >
                        </TextInput>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-main">
                    <div class="col-title">发动机号码</div>
                    <div class="col-form">
                      <el-form-item>
                        <TextInput
                          v-model="subject.engineNumber"
                          :isText="!controlEdit['6']"
                          :config="{
                            type: 'input',
                            width: '100%',
                          }"
                        >
                        </TextInput>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="row-main">
                  <div class="col-main">
                    <div class="col-title">注册日期</div>
                    <div class="col-form">
                      <el-form-item>
                        <TextInput
                          v-model="subject.registrationDate"
                          :isText="!controlEdit['6']"
                          :config="{
                            type: 'datePicker',
                            width: '100%',
                            dateFormat: 'YYYY-MM-DD',
                          }"
                        >
                        </TextInput>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-main">
                    <div class="col-title">发证日期</div>
                    <div class="col-form">
                      <el-form-item>
                        <TextInput
                          v-model="subject.issueDate"
                          :isText="!controlEdit['6']"
                          :config="{
                            type: 'datePicker',
                            width: '100%',
                            dateFormat: 'YYYY-MM-DD',
                          }"
                        >
                        </TextInput>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-main">
                    <div class="col-title">使用性质</div>
                    <div class="col-form">
                      <el-form-item>
                        <TextInput
                          @returnVal="returnValOption($event, subject)"
                          v-model="subject.usageNatureValue"
                          :isText="!controlEdit['6']"
                          :config="{
                            type: 'select',
                            width: '100%',
                            option: dictList.NewEnergyUseNature,
                            modelTextKey: 'usageNature',
                          }"
                          :optionConfig="{
                            value: 'dictKey',
                            label: 'dictValue',
                          }"
                        >
                        </TextInput>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="col-main">
                    <div class="col-title">品牌型号</div>
                    <div class="col-form">
                      <el-form-item>
                        <TextInput
                          v-model="subject.brandModel"
                          :isText="!controlEdit['6']"
                          :config="{
                            type: 'input',
                            width: '100%',
                          }"
                        >
                        </TextInput>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="row-main">
                  <div class="col-main">
                    <div class="col-title">所有人</div>
                    <div class="col-form">
                      <el-form-item>
                        <TextInput
                          v-model="subject.owner"
                          :isText="!controlEdit['6']"
                          :config="{
                            type: 'input',
                            width: '100%',
                          }"
                        >
                        </TextInput>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-main">
                    <div class="col-title">住址</div>
                    <div class="col-form">
                      <el-form-item>
                        <TextInput
                          v-model="subject.address"
                          :isText="!controlEdit['6']"
                          :config="{
                            type: 'input',
                            width: '100%',
                          }"
                        >
                        </TextInput>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
              <el-button
                v-if="controlEdit['6'] && index"
                @click="delItem(dataForm.subjectInfoList, index)"
                class="base-btn subject-item-btn"
                icon="el-icon-delete"
                >删除</el-button
              >
            </div>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="案件状态" name="3">
          <template slot="head-right">
            <el-button @click="nextSteps" class="base-btn green-btn"
              >下一步</el-button
            >
          </template>
          <div class="case-bar-main">
            <div class="case-bar" :style="`--bg-bar:${setBarStatusBg()}`">
              <div class="case-bar-point">
                <div v-for="(item, index) in caseSteps">
                  <div v-if="item.type == 1" class="case-bar-point-item"></div>
                  <div v-if="item.type == 2" class="case-bar-point-current">
                    <div class="case-bar-point-current-inner-round"></div>
                  </div>
                  <div v-if="item.type == 3" class="case-bar-point-complete">
                    <i
                      class="el-icon-check"
                      style="color: #ffffff; font-size: 20px; font-weight: bold"
                    ></i>
                  </div>
                  <div class="case-bar-point-bottom">
                    <div class="case-bar-point-bottom-title">
                      {{ item.title }}
                    </div>
                    <div class="case-bar-point-bottom-tips">
                      {{ item.tips }}
                    </div>
                    <div class="case-bar-point-bottom-date">
                      {{ item.date }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="定损信息" name="4">
          <div>
            <SimpleTable
              :tableProps="lossAssessmentTableConfig"
              :tableData="dataForm?.claimsLossItemList || []"
            >
              <div class="total" slot="table-top">
                <div class="total-title">赔付总金额(元)：</div>
                <div class="total-value">
                  {{
                    addThousandSeparator(
                      dataForm?.assessmentInfo?.payableAmount || 0
                    )
                  }}
                </div>
              </div>
              <template slot="lossItemName" slot-scope="{ data }">
                <TextInput
                  :isText="!controlEdit['7']"
                  v-model="data.row.lossItemName"
                  :querySearchAsync="querySearchAsyncLossItemName"
                  @handleSelectQuerySearchAsync="
                    handleSelectSearchAsyncLossItemName($event, data.index)
                  "
                  :config="{
                    type: 'searchInput',
                    width: '100%',
                  }"
                ></TextInput>
              </template>
              <template slot="attachmentQualityValue" slot-scope="{ data }"
                ><TextInput
                  :isText="!controlEdit['7']"
                  v-model="data.row.attachmentQualityValue"
                  :config="{
                    type: 'select',
                    width: '100%',
                    option: dictList.NewEnergyAttachmentQuality,
                  }"
                  :optionConfig="{
                    value: 'dictKey',
                    label: 'dictValue',
                  }"
                ></TextInput
              ></template>
              <template slot="guidePrice" slot-scope="{ data }">
                <TextInput
                  :isText="!controlEdit['7']"
                  v-model="data.row.guidePrice"
                  :config="{
                    type: 'input',
                    isNum: true,
                    width: '100%',
                  }"
                ></TextInput>
              </template>
              <template slot="actualPrice" slot-scope="{ data }"
                ><TextInput
                  :isText="!controlEdit['7']"
                  v-model="data.row.actualPrice"
                  :config="{
                    type: 'input',
                    isNum: true,
                    width: '100%',
                  }"
                ></TextInput
              ></template>
              <template slot="count" slot-scope="{ data }"
                ><TextInput
                  :isText="!controlEdit['7']"
                  v-model="data.row.count"
                  :config="{
                    type: 'input',
                    isNum: true,
                    width: '100%',
                  }"
                ></TextInput
              ></template>
              <template slot="amount" slot-scope="{ data }"
                ><TextInput
                  @returnVal="calcamountPayable"
                  :isText="!controlEdit['7']"
                  v-model="data.row.amount"
                  :config="{
                    type: 'input',
                    isNum: true,
                    width: '100%',
                    modelTextKey: 'amount',
                  }"
                ></TextInput
              ></template>
              <template slot="existsTax" slot-scope="{ data }">
                <!-- <TextInput
                  v-model="data.row.existsTax"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                ></TextInput> -->
                <el-switch
                  :disabled="!controlEdit['7']"
                  v-model="data.row.existsTax"
                  active-color="#007BFF"
                  inactive-color="#EAEDF3"
                  active-text="是"
                  inactive-text="否"
                >
                </el-switch>
              </template>
              <template slot="residualPrice" slot-scope="{ data }"
                ><TextInput
                  @returnVal="calcResidualPrice"
                  :isText="!controlEdit['7']"
                  v-model="data.row.residualPrice"
                  :config="{
                    type: 'input',
                    isNum: true,
                    width: '100%',
                    modelTextKey: 'residualPrice',
                  }"
                ></TextInput
              ></template>
              <template slot="remark" slot-scope="{ data }"
                ><TextInput
                  :isText="!controlEdit['7']"
                  v-model="data.row.remark"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                ></TextInput
              ></template>
              <template slot="action" slot-scope="{ data }">
                <el-button
                  v-if="controlEdit['7']"
                  @click="delItem(dataForm.claimsLossItemList, data.index)"
                  type="text"
                  style="color: #f64a2d"
                  >删除</el-button
                >
              </template>
            </SimpleTable>
            <el-button
              v-if="controlEdit['7']"
              @click="addLossAssessmentItem"
              class="log-btn"
              size="mini"
              >添加定损项</el-button
            >
          </div>
          <div class="table-layout">
            <el-form-item
              prop="assessmentInfo.insureRate"
              :rules="{
                required: lastStatusKey == '7',
                message: '请输入投保比例',
                trigger: 'change',
              }"
            >
              <TextInput
                @returnVal="calcamountPayable"
                :isText="!controlEdit['7']"
                v-model="dataForm.assessmentInfo.insureRate"
                :config="{
                  type: 'input',
                  label: '投保比例：',
                  isNum: true,
                  required: true,
                  modelTextKey: 'insureRate',
                }"
              >
                <div slot="inputAppend">%</div>
              </TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                @returnVal="calcamountPayable"
                :isText="!controlEdit['7']"
                v-model="dataForm.assessmentInfo.caseLiabilityRate"
                :config="{
                  type: 'input',
                  label: '案件责任比例：',
                  isNum: true,
                  modelTextKey: 'caseLiabilityRate',
                }"
                ><div slot="inputAppend">%</div>
              </TextInput>
            </el-form-item>
            <el-form-item
              prop="assessmentInfo.totalDeductible"
              :rules="{
                required: lastStatusKey == '7',
                message: '请输入免赔金额',
                trigger: 'change',
              }"
            >
              <TextInput
                @returnVal="calcamountPayable"
                :isText="!controlEdit['7']"
                v-model="dataForm.assessmentInfo.totalDeductible"
                :config="{
                  type: 'input',
                  label: '免赔金额：',
                  required: true,
                  isNum: true,
                  modelTextKey: 'totalDeductible',
                }"
                ><div style="width: 26px" slot="inputAppend">
                  <el-select
                    @change="calcamountPayable"
                    v-model="dataForm.assessmentInfo.totalDeductibleUnit"
                    placeholder=""
                  >
                    <el-option
                      v-for="dic in dictList.unit"
                      :key="dic.value"
                      :label="dic.label"
                      :value="dic.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!controlEdit['7']"
                v-model="dataForm.assessmentInfo.buyDeductible"
                :config="{
                  type: 'radio',
                  label: '是否购买不计免赔：',
                  option: dictList.yesNo,
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                v-model="dataForm.assessmentInfo.salvageSum"
                :isText="!controlEdit['7']"
                :config="{
                  type: 'input',
                  label: '残值合计：',
                  disabled: true,
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!controlEdit['7']"
                v-model="dataForm.assessmentInfo.valueAdded"
                :config="{
                  type: 'input',
                  isNum: true,
                  label: '增值税合计：',
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                v-model="dataForm.assessmentInfo.amountPayable"
                :isText="!controlEdit['7']"
                :config="{
                  type: 'input',
                  label: '应赔金额：',
                  disabled: true,
                }"
              >
              </TextInput>
            </el-form-item>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="索赔资料" name="5">
          <template v-if="controlEdit['8']" slot="head-right">
            <el-button
              @click="addPayee"
              icon="el-icon-circle-plus-outline"
              class="base-btn green-btn"
              >添加收款方</el-button
            >
          </template>
          <div>
            <div v-for="(demand, index) in dataForm.demandInfoList">
              <div class="table-layout">
                <el-form-item prop="assessmentInfo.insureRate">
                  <TextInput
                    :isText="!controlEdit['8']"
                    v-model="demand.payeeType"
                    :config="{
                      type: 'radio',
                      label: '用户类型：',
                      option: dictList.userType,
                    }"
                  >
                  </TextInput>
                </el-form-item>
                <template v-if="demand.payeeType == 1">
                  <el-form-item
                    :rules="{
                      required: lastStatusKey == '8',
                      message: '请输入公司名称',
                      trigger: 'change',
                    }"
                    :prop="`demandInfoList.${index}.corporateName`"
                  >
                    <TextInput
                      :isText="!controlEdit['8']"
                      v-model="demand.corporateName"
                      :config="{
                        type: 'input',
                        label: '公司名称：',
                        required: true,
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                  <el-form-item
                    :rules="{
                      required: lastStatusKey == '8',
                      message: '请输入营业执照编号',
                      trigger: 'change',
                    }"
                    :prop="`demandInfoList.${index}.businessLicenseNo`"
                  >
                    <TextInput
                      :isText="!controlEdit['8']"
                      v-model="demand.businessLicenseNo"
                      :config="{
                        type: 'input',
                        label: '营业执照编号：',
                        required: true,
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </template>
                <template v-if="demand.payeeType == 2">
                  <el-form-item
                    :rules="{
                      required: lastStatusKey == '8',
                      message: '请输入请输入姓名',
                      trigger: 'change',
                    }"
                    :prop="`demandInfoList.${index}.name`"
                  >
                    <TextInput
                      :isText="!controlEdit['8']"
                      v-model="demand.name"
                      :config="{
                        type: 'input',
                        label: '姓名：',
                        required: true,
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                  <el-form-item
                    :rules="{
                      required: lastStatusKey == '8',
                      message: '请输入身份证号码',
                      trigger: 'change',
                    }"
                    :prop="`demandInfoList.${index}.number`"
                  >
                    <TextInput
                      :isText="!controlEdit['8']"
                      v-model="demand.number"
                      :config="{
                        type: 'input',
                        label: '身份证号码：',
                        required: true,
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </template>

                <el-form-item>
                  <TextInput
                    :isText="!controlEdit['8']"
                    v-model="demand.phone"
                    :config="{
                      type: 'input',
                      label: '电话：',
                      isNum: true,
                    }"
                  >
                  </TextInput>
                </el-form-item>
                <el-form-item>
                  <TextInput
                    v-model="demand.address"
                    :isText="!controlEdit['8']"
                    :config="{
                      type: 'input',
                      label: '地址：',
                    }"
                  >
                  </TextInput>
                </el-form-item>
                <el-form-item
                  :rules="{
                    required: lastStatusKey == '8',
                    message: '请输入账号',
                    trigger: 'change',
                  }"
                  :prop="`demandInfoList.${index}.bankNumber`"
                >
                  <TextInput
                    :isText="!controlEdit['8']"
                    v-model="demand.bankNumber"
                    :config="{
                      type: 'input',
                      label: '账号：',
                      isNum: true,
                      required: true,
                    }"
                  >
                  </TextInput>
                </el-form-item>
                <el-form-item
                  :rules="{
                    required: lastStatusKey == '8',
                    message: '请输入开户行',
                    trigger: 'change',
                  }"
                  :prop="`demandInfoList.${index}.bankOfDeposit`"
                >
                  <TextInput
                    v-model="demand.bankOfDeposit"
                    :isText="!controlEdit['8']"
                    :config="{
                      type: 'input',
                      label: '开户行：',
                      required: true,
                    }"
                  >
                  </TextInput>
                </el-form-item>
                <el-form-item>
                  <TextInput
                    v-model="demand.amountPaid"
                    :isText="!controlEdit['8']"
                    :config="{
                      type: 'input',
                      label: '赔付金额：',
                      isNum: true,
                    }"
                  >
                  </TextInput>
                </el-form-item>
              </div>
              <div
                style="
                  margin-top: 24px;
                  display: flex;
                  justify-content: flex-end;
                "
              >
                <el-button
                  v-if="controlEdit['8']"
                  @click="delItem(dataForm.demandInfoList, index)"
                  class="base-btn"
                  style="background-color: #f56c6c"
                  icon="el-icon-delete"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
        </AssociationScrollView>
      </AssociationScroll>
    </el-form>
    <div class="action-footer">
      <el-button @click="back" class="base-btn white-btn">返回</el-button>
      <el-button @click="viewFile" class="base-btn white-btn"
        >查看附件</el-button
      >
      <el-button
        @click="submit"
        class="base-btn"
        icon="el-icon-circle-check"
        style="background-color: #4278c9"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import TextInput from "@/components/SimpleTable/TextInput.vue";
import SimpleTable from "@/components/SimpleTable";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import BoxUpload from "@/components/DragMultipleUpload/BoxUpload";
import { addThousandSeparator } from "@/utils/dealNumber";
import { dictionaryBatch } from "@/api/policy";
import {
  caseNewEnergyDetail,
  caseNewEnergyStep,
  editNewEnergyCase,
  getNewEnergyClaimLossItemByName,
} from "@/api/newEnergyclaim";
export default {
  name: "newEnergyClaimDetails",
  components: {
    AssociationScroll,
    AssociationScrollView,
    TextInput,
    SimpleTable,
    EmployeeSelect,
    BoxUpload,
  },
  data() {
    return {
      addThousandSeparator: addThousandSeparator,
      caseId: "",
      dictList: {
        // 是否
        yesNo: [
          { label: "是", value: true },
          { label: "否", value: false },
        ],
        yesNoNum: [
          { label: "是", value: 1 },
          { label: "否", value: 0 },
        ],
        // 单位
        unit: [
          { label: "元", value: 1 },
          { label: "%", value: 2 },
        ],
        // 用户类型
        userType: [
          { label: "企业", value: 1 },
          { label: "个人", value: 2 },
        ],
      },
      dataForm: {
        assessmentInfo: {
          // 辅助用
          payableAmount: 0,
          amountPayable: "",
          buyDeductible: true,
          caseLiabilityRate: "",
          insureRate: "",
          salvageSum: "",
          totalDeductible: "",
          totalDeductibleUnit: 1,
          valueAdded: "",
        },
        claimsLossItemList: [],
        caseInfo: {
          accidentType: "",
          caseFollower: "",
          caseFollowerId: "",
          caseFollowerPhone: "",
          dangerDate: "",
          dangerDetailAddress: "",
          dangerDetailAddressLonlat: "",
          dangerRemark: "",
          isPersonalInjury: 0,
          policyCompany: "",
          policyEndDate: "",
          policyNo: "",
          policyStartDate: "",
          realCaseNo: "",
          reportCasePersonName: "",
          reportCasePhone: "",
          reportCaseTime: "",
          accidentCauseValue: "",
          accidentCause: "",
        },
        demandInfoList: [
          {
            address: "",
            amountPaid: "",
            bankNumber: "",
            bankOfDeposit: "",
            businessLicenseNo: "",
            claimDataSheetId: "",
            corporateName: "",
            legalPersonName: "",
            legalPersonNumber: "",
            name: "",
            number: "",
            payeeType: "",
            personInCharge: "",
            phone: "",
          },
        ],
        insClaimsAttachmentList: [],
        subjectInfoList: [
          {
            address: "",
            brandModel: "",
            engineNumber: "",
            issueDate: "",
            licensePlate: "",
            owner: "",
            registrationDate: "",
            usageNature: "",
            vehicleType: "",
            vehicleTypeValue: "",
            usageNatureValue: "",
            vin: "",
            isThree: false,
          },
        ],
      },
      currentRules: {},
      lastStatusKey: "",
      caseSteps: [
        {
          title: "已接案",
          tips: "（出险信息）",
          key: 5,
          date: "",
          type: 1,
        },
        {
          title: "验标",
          tips: "（验标照片和视频）",
          key: 6,
          date: "",
          type: 1,
        },
        {
          title: "定损",
          tips: "（定损信息和单据）",
          key: 7,
          date: "",
          type: 1,
        },
        {
          title: "索赔",
          tips: "（索赔材料单据）",
          key: 8,
          date: "",
          type: 1,
        },
        {
          title: "理算结案",
          tips: "（理算/核赔）",
          key: 9,
          date: "",
          type: 1,
        },
      ],
      controlEdit: {
        5: false,
        6: false,
        7: false,
        8: false,
      },
      lossAssessmentTableConfig: {
        isSelection: false,
        isColumnSet: false,
        isHiddenSet: false,
        isSearch: false,
        isPagination: false,
        isRefresh: false,
        isFuzzyQuery: false,
        isFillData: true,
        isTableTop: false,
        border: true,
        height: 280,
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "lossItemName",
            label: "定损项名称",
            isShow: true,
            slot: true,
            slot: true,
          },
          {
            prop: "attachmentQualityValue",
            label: "配件品质",
            isShow: true,
            slot: true,
          },
          {
            prop: "guidePrice",
            label: "指导单价",
            isShow: true,
            slot: true,
          },
          {
            prop: "actualPrice",
            label: "实际单价",
            isShow: true,
            slot: true,
          },
          {
            prop: "count",
            label: "数量",
            isShow: true,
            slot: true,
          },
          {
            prop: "amount",
            label: "金额",
            isShow: true,
            slot: true,
          },
          {
            prop: "existsTax",
            label: "是否含税",
            isShow: true,
            slot: true,
          },
          {
            prop: "residualPrice",
            label: "残值金额",
            isShow: true,
            slot: true,
          },
          {
            prop: "remark",
            label: "备注",
            isShow: true,
            slot: true,
          },
          {
            prop: "action",
            label: "操作",
            isShow: true,
            width: 80,
            slot: true,
          },
        ],
      },
    };
  },
  activated() {
    this.$route.params.id && (this.caseId = this.$route.params.id);
    this.getDictionary();
    this.init();
  },
  methods: {
    init() {
      caseNewEnergyDetail({ caseId: this.caseId }).then((res) => {
        this.dataForm = res.data;
        this.setDataDefault();
        this.handleCaseStatus();
        this.calcPayableAmount();
        this.$forceUpdate();
      });
    },
    // 设置部分数据默认值
    setDataDefault() {
      this.dataForm.assessmentInfo.totalDeductibleUnit == ""
        ? (this.dataForm.assessmentInfo.totalDeductibleUnit = 1)
        : "";
      this.dataForm.assessmentInfo.buyDeductible == ""
        ? (this.dataForm.assessmentInfo.buyDeductible = false)
        : "";
    },
    // 字典获取
    getDictionary() {
      let dictKeys = [
        "NewEnergyAttachmentQuality",
        "NewEnergyVehicleType",
        "NewEnergyUseNature",
        "NewEnergyAccidentReason",
      ];
      dictionaryBatch({
        codes: dictKeys.join(","),
      }).then((res) => {
        if (res && res.data) {
          dictKeys.forEach((key) => {
            this.$set(this.dictList, key, res.data[key] || []);
          });
        }
      });
    },
    // 处理案件状态
    handleCaseStatus() {
      let caseStatus = [];
      let statusDate = this.dataForm?.statusDatetime?.split(",");
      this.dataForm?.status?.split(",")?.forEach((st, si) => {
        caseStatus.push({
          key: st,
          date: statusDate[si] || "",
        });
      });
      this.lastStatusKey = caseStatus[caseStatus.length - 1].key;
      this.setBarPointStatusByCaseStatus(caseStatus);
      this.handelDataBlockEdit(true);
    },
    // 根据案件当前状态设置进度条状态
    setBarPointStatusByCaseStatus(caseStatus = []) {
      this.caseSteps.forEach((item) => {
        if (item.key == this.lastStatusKey) {
          item.type = 2;
        } else if (item.key < this.lastStatusKey) {
          item.type = 3;
        } else if (item.key > this.lastStatusKey) {
          item.type = 1;
        }
        caseStatus.findIndex((citem) => {
          if (citem.key == item.key) {
            item.date = citem.date;
            return true;
          } else {
            item.date = "";
          }
        });
      });
    },
    // 设置步骤条状态
    setBarStatusBg() {
      let index = 0;
      this.caseSteps.some((item, i) => {
        if (item.type == 2) {
          index = i;
          return true;
        }
      });
      return `${(100 / (this.caseSteps.length - 1)) * index}%`;
    },
    // 控制数据编辑
    handelDataBlockEdit(edit = false) {
      for (const key in this.controlEdit) {
        if (this.lastStatusKey > key) {
          this.controlEdit[key] = false;
        } else {
          this.controlEdit[key] = edit;
        }
      }
    },
    // 下一步
    nextSteps() {
      this.$confirm("是否进行下一步?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          caseNewEnergyStep({ caseId: this.caseId }).then((res) => {
            this.init();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //
    addTriCardSubject() {
      this.dataForm.subjectInfoList.push({
        address: "",
        brandModel: "",
        engineNumber: "",
        issueDate: "",
        licensePlate: "",
        owner: "",
        registrationDate: "",
        usageNature: "",
        vehicleType: "",
        vin: "",
        isThree: true,
      });
    },
    addLossAssessmentItem() {
      this.dataForm.claimsLossItemList.push({
        actualPrice: "",
        amount: "",
        attachmentQualityName: "",
        attachmentQualityValue: "",
        brand: "",
        count: "",
        existsTax: true,
        guidePrice: "",
        lossItemName: "",
        lossType: "",
        remark: "",
        reopenCount: "",
        residualPrice: "",
      });
    },
    addPayee() {
      this.dataForm.demandInfoList.push({
        address: "",
        amountPaid: "",
        bankNumber: "",
        bankOfDeposit: "",
        businessLicenseNo: "",
        corporateName: "",
        legalPersonName: "",
        legalPersonNumber: "",
        name: "",
        number: "",
        payeeType: 1,
        personInCharge: "",
        phone: "",
      });
    },
    // 删除
    delItem(array = [], index) {
      array.splice(index, 1);
    },
    changeCaseFollower(e) {
      this.dataForm.caseInfo.caseFollower = e.title;
    },
    // 计算残值合计
    calcResidualPrice(e) {
      let total = 0;
      this.dataForm.claimsLossItemList.forEach((item) => {
        total += Number(item.residualPrice || 0);
      });
      this.dataForm.assessmentInfo.salvageSum = total;
      this.calcamountPayable();
    },
    // 计算应赔金额
    calcamountPayable() {
      let total = 0;
      this.dataForm.claimsLossItemList.forEach((item) => {
        total += Number(item.amount || 0);
      });
      total = total - this.dataForm.assessmentInfo.salvageSum || 0;
      if (this.dataForm.assessmentInfo.totalDeductibleUnit == 1) {
        total =
          total *
            ((this.dataForm.assessmentInfo.insureRate || 0) / 100) *
            ((this.dataForm.assessmentInfo.caseLiabilityRate || 0) / 100) -
          this.dataForm.assessmentInfo.totalDeductible;
      } else if (this.dataForm.assessmentInfo.totalDeductibleUnit == 2) {
        total =
          total *
          ((this.dataForm.assessmentInfo.insureRate || 0) / 100) *
          ((this.dataForm.assessmentInfo.caseLiabilityRate || 0) / 100) *
          (1 - (this.dataForm.assessmentInfo.totalDeductible || 0) / 100);
      }
      this.dataForm.assessmentInfo.amountPayable = total.toFixed(4);
      this.calcPayableAmount();
    },
    // 计算赔付总金额
    calcPayableAmount() {
      let total = 0;
      this.dataForm.claimsLossItemList.forEach((item) => {
        total += Number(item.amount || 0);
      });
      this.$set(this.dataForm.assessmentInfo, "payableAmount", total);
    },
    handleSelectSearchAsyncLossItemName(item, index) {
      this.dataForm.claimsLossItemList.splice(index, 1, item);
    },
    back() {
      this.$router.go(-1);
    },
    querySearchAsyncLossItemName(str, cb) {
      getNewEnergyClaimLossItemByName({
        caseId: this.caseId,
        itemName: str,
      }).then((res) => {
        let list = res.data.map((item) => {
          return {
            ...item,
            value: item.lossItemName,
          };
        });
        cb(list);
      });
    },
    viewFile() {
      this.$router.push({
        name: "newEnergyClaimAttachmentDetails",
        params: {
          id: this.dataForm.id,
          latestStatus: this.lastStatusKey,
        },
      });
    },
    returnValOption(params, obj) {
      obj[params.type] = params.data.dictValue;
    },
    submit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          editNewEnergyCase(this.dataForm).then((res) => {
            this.$message({
              showClose: true,
              message: "保存成功！",
              type: "success",
            });
          });
        } else {
          this.$message({
            showClose: true,
            message: "请补充完整必要信息！",
            type: "warning",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0;
  width: 100%;
}
.table-layout {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  place-items: center;
  justify-items: stretch;
}
.main-content {
  position: relative;
}
.action-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;
}

.base-btn {
  height: 32px;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border: none;
  border-radius: 4px 4px 4px 4px;
}
.white-btn {
  border: 1px solid #cccccc;
  color: #333333;
}
.green-btn {
  background: #00bc0d;
}
.log-btn {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #00bc0d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
}
.case-bar-main {
  min-height: 200px;
  display: flex;
  padding: 40px 80px 0px 80px;
}
.case-bar {
  width: 100%;
  height: 12px;
  background: linear-gradient(
    to right,
    #4278c9 0%,
    #4278c9 var(--bg-bar),
    #eeeeee var(--bg-bar),
    #eeeeee 100%
  );
  position: relative;
  border-radius: 20px;
  &-point {
    width: 100%;
    position: relative;
    top: -14px;
    display: flex;
    justify-content: space-between;
    &-item {
      background-color: #eeeeee;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    &-complete {
      background-color: #4278c9;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 3px 6px 1px rgba(97, 159, 252, 0.6);
      border: 2px solid #ffffff;
    }
    &-current {
      background-color: #cfe0ff;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 3px 6px 1px rgba(97, 159, 252, 0.6);
      &-inner-round {
        width: 24px;
        height: 24px;
        background-color: #4278c9;
        border-radius: 50%;
        border: 4px solid #ffffff;
      }
    }
    &-bottom {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 160px;
      transform: translateX(-60px);
      margin-top: 12px;
      &-title {
        font-weight: bold;
        font-size: 18px;
        color: #4278c9;
        text-align: center;
      }
      &-date {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        text-align: center;
      }
      &-tips {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        text-align: center;
      }
    }
  }
}
.layout-main {
  border-top: 1px solid #dee3e6;
  border-left: 1px solid #dee3e6;
  box-sizing: border-box;
  flex: 1;
}
.row-main {
  border-bottom: 1px solid #dee3e6;
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
.col-main {
  display: flex;
  flex: 1;
  .col-title {
    width: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #f2f7fd;
    border-right: 1px solid #dee3e6;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-form {
    display: flex;
    flex: 1;
    width: 0;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-right: 1px solid #dee3e6;
    box-sizing: border-box;
    padding: 0 8px;
  }
}
.subject {
  &-item {
    display: flex;
    &-btn {
      background: #f56c6c;
      margin-left: 16px;
    }
  }
}
.total {
  display: flex;
  align-items: center;
  margin: 16px 0;
  &-ttitle {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
  &-value {
    font-weight: bold;
    font-size: 18px;
    color: #3470ff;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('el-form',{ref:"dataForm",attrs:{"model":_vm.dataForm,"rules":_vm.currentRules}},[_c('AssociationScroll',[_c('AssociationScrollView',{attrs:{"title":"报案信息","name":"0"}},[_c('div',{staticClass:"layout-main",staticStyle:{"margin-top":"16px"}},[_c('div',{staticClass:"row-main"},[_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("报案人")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'input',
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.reportCasePersonName),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "reportCasePersonName", $$v)},expression:"dataForm.caseInfo.reportCasePersonName"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("出险时间")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'datePicker',
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.dangerDate),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "dangerDate", $$v)},expression:"dataForm.caseInfo.dangerDate"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("报案时间")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'datePicker',
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.reportCaseTime),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "reportCaseTime", $$v)},expression:"dataForm.caseInfo.reportCaseTime"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("事故类型")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'select',
                      width: '100%',
                      option: _vm.dictList.NewEnergyAccidentReason,
                      modelTextKey: 'accidentCause',
                    },"optionConfig":{
                      value: 'dictKey',
                      label: 'dictValue',
                    }},on:{"returnVal":function($event){return _vm.returnValOption($event, _vm.dataForm.caseInfo)}},model:{value:(_vm.dataForm.caseInfo.accidentCauseValue),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "accidentCauseValue", $$v)},expression:"dataForm.caseInfo.accidentCauseValue"}})],1)],1)])]),_c('div',{staticClass:"row-main"},[_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("报案人电话")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'input',
                      isNum: true,
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.reportCasePhone),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "reportCasePhone", $$v)},expression:"dataForm.caseInfo.reportCasePhone"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("案件跟进人")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'slot',
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.caseFollower),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "caseFollower", $$v)},expression:"dataForm.caseInfo.caseFollower"}},[(_vm.controlEdit['5'])?_c('EmployeeSelect',{attrs:{"placeholder":"请选择"},on:{"changeChecked":_vm.changeCaseFollower},model:{value:(_vm.dataForm.caseInfo.caseFollowerId),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "caseFollowerId", $$v)},expression:"dataForm.caseInfo.caseFollowerId"}}):_c('span',[_vm._v(_vm._s(_vm.dataForm.caseInfo.caseFollower))])],1)],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("案件跟进人电话")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'input',
                      isNum: true,
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.caseFollowerPhone),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "caseFollowerPhone", $$v)},expression:"dataForm.caseInfo.caseFollowerPhone"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("是否有人伤")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'select',
                      width: '100%',
                      option: _vm.dictList.yesNoNum,
                    }},model:{value:(_vm.dataForm.caseInfo.isPersonalInjury),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "isPersonalInjury", $$v)},expression:"dataForm.caseInfo.isPersonalInjury"}})],1)],1)])]),_c('div',{staticClass:"row-main"},[_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("出险地点")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      modelKey: 'dangerDetailAddress',
                      type: 'location',
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.dangerDetailAddress),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "dangerDetailAddress", $$v)},expression:"dataForm.caseInfo.dangerDetailAddress"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("案件备注")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'input',
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.dangerRemark),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "dangerRemark", $$v)},expression:"dataForm.caseInfo.dangerRemark"}})],1)],1)])])])]),_c('AssociationScrollView',{attrs:{"title":"保险信息","name":"1"}},[_c('div',{staticClass:"layout-main",staticStyle:{"margin-top":"16px"}},[_c('div',{staticClass:"row-main"},[_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("保险公司")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'input',
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.policyCompany),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "policyCompany", $$v)},expression:"dataForm.caseInfo.policyCompany"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("保单号")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'input',
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.policyNo),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "policyNo", $$v)},expression:"dataForm.caseInfo.policyNo"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("保费")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'input',
                      isNum: true,
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.premium),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "premium", $$v)},expression:"dataForm.caseInfo.premium"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("报案号")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'input',
                      width: '100%',
                    }},model:{value:(_vm.dataForm.caseInfo.realCaseNo),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "realCaseNo", $$v)},expression:"dataForm.caseInfo.realCaseNo"}})],1)],1)])]),_c('div',{staticClass:"row-main"},[_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("起始时间")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'datePicker',
                      width: '100%',
                      dateFormat: 'YYYY-MM-DD 00:00:00',
                    }},model:{value:(_vm.dataForm.caseInfo.policyStartDate),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "policyStartDate", $$v)},expression:"dataForm.caseInfo.policyStartDate"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("结束时间")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['5'],"config":{
                      type: 'datePicker',
                      width: '100%',
                      dateFormat: 'YYYY-MM-DD 23:59:59',
                    }},model:{value:(_vm.dataForm.caseInfo.policyEndDate),callback:function ($$v) {_vm.$set(_vm.dataForm.caseInfo, "policyEndDate", $$v)},expression:"dataForm.caseInfo.policyEndDate"}})],1)],1)])])]),_c('el-form-item',[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("电子保单：")]),_c('BoxUpload',{attrs:{"count":0,"disabled":!_vm.controlEdit['5'],"attachmentType":"XNYLPDZBD"},model:{value:(_vm.dataForm.insClaimsAttachmentList),callback:function ($$v) {_vm.$set(_vm.dataForm, "insClaimsAttachmentList", $$v)},expression:"dataForm.insClaimsAttachmentList"}})],1)],1),_c('AssociationScrollView',{attrs:{"title":"标的信息","name":"2"}},[(_vm.controlEdit['6'])?_c('template',{slot:"head-right"},[_c('el-button',{staticClass:"base-btn green-btn",attrs:{"icon":"el-icon-circle-plus-outline"},on:{"click":_vm.addTriCardSubject}},[_vm._v("添加三者车辆")])],1):_vm._e(),_c('div',{staticClass:"subject"},_vm._l((_vm.dataForm.subjectInfoList),function(subject,index){return _c('div',{staticClass:"subject-item",staticStyle:{"margin-top":"16px"}},[_c('div',{staticClass:"layout-main"},[_c('div',{staticClass:"row-main"},[_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("车牌号码")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['6'],"config":{
                          type: 'input',
                          width: '100%',
                        }},model:{value:(subject.licensePlate),callback:function ($$v) {_vm.$set(subject, "licensePlate", $$v)},expression:"subject.licensePlate"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("车辆类型")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['6'],"config":{
                          type: 'select',
                          width: '100%',
                          option: _vm.dictList.NewEnergyVehicleType,
                          modelTextKey: 'vehicleType',
                        },"optionConfig":{
                          value: 'dictKey',
                          label: 'dictValue',
                        }},on:{"returnVal":function($event){return _vm.returnValOption($event, subject)}},model:{value:(subject.vehicleTypeValue),callback:function ($$v) {_vm.$set(subject, "vehicleTypeValue", $$v)},expression:"subject.vehicleTypeValue"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("车辆识别号")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['6'],"config":{
                          type: 'input',
                          width: '100%',
                        }},model:{value:(subject.vin),callback:function ($$v) {_vm.$set(subject, "vin", $$v)},expression:"subject.vin"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("发动机号码")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['6'],"config":{
                          type: 'input',
                          width: '100%',
                        }},model:{value:(subject.engineNumber),callback:function ($$v) {_vm.$set(subject, "engineNumber", $$v)},expression:"subject.engineNumber"}})],1)],1)])]),_c('div',{staticClass:"row-main"},[_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("注册日期")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['6'],"config":{
                          type: 'datePicker',
                          width: '100%',
                          dateFormat: 'YYYY-MM-DD',
                        }},model:{value:(subject.registrationDate),callback:function ($$v) {_vm.$set(subject, "registrationDate", $$v)},expression:"subject.registrationDate"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("发证日期")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['6'],"config":{
                          type: 'datePicker',
                          width: '100%',
                          dateFormat: 'YYYY-MM-DD',
                        }},model:{value:(subject.issueDate),callback:function ($$v) {_vm.$set(subject, "issueDate", $$v)},expression:"subject.issueDate"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("使用性质")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['6'],"config":{
                          type: 'select',
                          width: '100%',
                          option: _vm.dictList.NewEnergyUseNature,
                          modelTextKey: 'usageNature',
                        },"optionConfig":{
                          value: 'dictKey',
                          label: 'dictValue',
                        }},on:{"returnVal":function($event){return _vm.returnValOption($event, subject)}},model:{value:(subject.usageNatureValue),callback:function ($$v) {_vm.$set(subject, "usageNatureValue", $$v)},expression:"subject.usageNatureValue"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("品牌型号")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['6'],"config":{
                          type: 'input',
                          width: '100%',
                        }},model:{value:(subject.brandModel),callback:function ($$v) {_vm.$set(subject, "brandModel", $$v)},expression:"subject.brandModel"}})],1)],1)])]),_c('div',{staticClass:"row-main"},[_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("所有人")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['6'],"config":{
                          type: 'input',
                          width: '100%',
                        }},model:{value:(subject.owner),callback:function ($$v) {_vm.$set(subject, "owner", $$v)},expression:"subject.owner"}})],1)],1)]),_c('div',{staticClass:"col-main"},[_c('div',{staticClass:"col-title"},[_vm._v("住址")]),_c('div',{staticClass:"col-form"},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['6'],"config":{
                          type: 'input',
                          width: '100%',
                        }},model:{value:(subject.address),callback:function ($$v) {_vm.$set(subject, "address", $$v)},expression:"subject.address"}})],1)],1)])])]),(_vm.controlEdit['6'] && index)?_c('el-button',{staticClass:"base-btn subject-item-btn",attrs:{"icon":"el-icon-delete"},on:{"click":function($event){return _vm.delItem(_vm.dataForm.subjectInfoList, index)}}},[_vm._v("删除")]):_vm._e()],1)}),0)],2),_c('AssociationScrollView',{attrs:{"title":"案件状态","name":"3"}},[_c('template',{slot:"head-right"},[_c('el-button',{staticClass:"base-btn green-btn",on:{"click":_vm.nextSteps}},[_vm._v("下一步")])],1),_c('div',{staticClass:"case-bar-main"},[_c('div',{staticClass:"case-bar",style:(`--bg-bar:${_vm.setBarStatusBg()}`)},[_c('div',{staticClass:"case-bar-point"},_vm._l((_vm.caseSteps),function(item,index){return _c('div',[(item.type == 1)?_c('div',{staticClass:"case-bar-point-item"}):_vm._e(),(item.type == 2)?_c('div',{staticClass:"case-bar-point-current"},[_c('div',{staticClass:"case-bar-point-current-inner-round"})]):_vm._e(),(item.type == 3)?_c('div',{staticClass:"case-bar-point-complete"},[_c('i',{staticClass:"el-icon-check",staticStyle:{"color":"#ffffff","font-size":"20px","font-weight":"bold"}})]):_vm._e(),_c('div',{staticClass:"case-bar-point-bottom"},[_c('div',{staticClass:"case-bar-point-bottom-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"case-bar-point-bottom-tips"},[_vm._v(" "+_vm._s(item.tips)+" ")]),_c('div',{staticClass:"case-bar-point-bottom-date"},[_vm._v(" "+_vm._s(item.date)+" ")])])])}),0)])])],2),_c('AssociationScrollView',{attrs:{"title":"定损信息","name":"4"}},[_c('div',[_c('SimpleTable',{attrs:{"tableProps":_vm.lossAssessmentTableConfig,"tableData":_vm.dataForm?.claimsLossItemList || []},scopedSlots:_vm._u([{key:"lossItemName",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"querySearchAsync":_vm.querySearchAsyncLossItemName,"config":{
                  type: 'searchInput',
                  width: '100%',
                }},on:{"handleSelectQuerySearchAsync":function($event){return _vm.handleSelectSearchAsyncLossItemName($event, data.index)}},model:{value:(data.row.lossItemName),callback:function ($$v) {_vm.$set(data.row, "lossItemName", $$v)},expression:"data.row.lossItemName"}})]}},{key:"attachmentQualityValue",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                  type: 'select',
                  width: '100%',
                  option: _vm.dictList.NewEnergyAttachmentQuality,
                },"optionConfig":{
                  value: 'dictKey',
                  label: 'dictValue',
                }},model:{value:(data.row.attachmentQualityValue),callback:function ($$v) {_vm.$set(data.row, "attachmentQualityValue", $$v)},expression:"data.row.attachmentQualityValue"}})]}},{key:"guidePrice",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                  type: 'input',
                  isNum: true,
                  width: '100%',
                }},model:{value:(data.row.guidePrice),callback:function ($$v) {_vm.$set(data.row, "guidePrice", $$v)},expression:"data.row.guidePrice"}})]}},{key:"actualPrice",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                  type: 'input',
                  isNum: true,
                  width: '100%',
                }},model:{value:(data.row.actualPrice),callback:function ($$v) {_vm.$set(data.row, "actualPrice", $$v)},expression:"data.row.actualPrice"}})]}},{key:"count",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                  type: 'input',
                  isNum: true,
                  width: '100%',
                }},model:{value:(data.row.count),callback:function ($$v) {_vm.$set(data.row, "count", $$v)},expression:"data.row.count"}})]}},{key:"amount",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                  type: 'input',
                  isNum: true,
                  width: '100%',
                  modelTextKey: 'amount',
                }},on:{"returnVal":_vm.calcamountPayable},model:{value:(data.row.amount),callback:function ($$v) {_vm.$set(data.row, "amount", $$v)},expression:"data.row.amount"}})]}},{key:"existsTax",fn:function({ data }){return [_c('el-switch',{attrs:{"disabled":!_vm.controlEdit['7'],"active-color":"#007BFF","inactive-color":"#EAEDF3","active-text":"是","inactive-text":"否"},model:{value:(data.row.existsTax),callback:function ($$v) {_vm.$set(data.row, "existsTax", $$v)},expression:"data.row.existsTax"}})]}},{key:"residualPrice",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                  type: 'input',
                  isNum: true,
                  width: '100%',
                  modelTextKey: 'residualPrice',
                }},on:{"returnVal":_vm.calcResidualPrice},model:{value:(data.row.residualPrice),callback:function ($$v) {_vm.$set(data.row, "residualPrice", $$v)},expression:"data.row.residualPrice"}})]}},{key:"remark",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                  type: 'input',
                  width: '100%',
                }},model:{value:(data.row.remark),callback:function ($$v) {_vm.$set(data.row, "remark", $$v)},expression:"data.row.remark"}})]}},{key:"action",fn:function({ data }){return [(_vm.controlEdit['7'])?_c('el-button',{staticStyle:{"color":"#f64a2d"},attrs:{"type":"text"},on:{"click":function($event){return _vm.delItem(_vm.dataForm.claimsLossItemList, data.index)}}},[_vm._v("删除")]):_vm._e()]}}])},[_c('div',{staticClass:"total",attrs:{"slot":"table-top"},slot:"table-top"},[_c('div',{staticClass:"total-title"},[_vm._v("赔付总金额(元)：")]),_c('div',{staticClass:"total-value"},[_vm._v(" "+_vm._s(_vm.addThousandSeparator( _vm.dataForm?.assessmentInfo?.payableAmount || 0 ))+" ")])])]),(_vm.controlEdit['7'])?_c('el-button',{staticClass:"log-btn",attrs:{"size":"mini"},on:{"click":_vm.addLossAssessmentItem}},[_vm._v("添加定损项")]):_vm._e()],1),_c('div',{staticClass:"table-layout"},[_c('el-form-item',{attrs:{"prop":"assessmentInfo.insureRate","rules":{
              required: _vm.lastStatusKey == '7',
              message: '请输入投保比例',
              trigger: 'change',
            }}},[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                type: 'input',
                label: '投保比例：',
                isNum: true,
                required: true,
                modelTextKey: 'insureRate',
              }},on:{"returnVal":_vm.calcamountPayable},model:{value:(_vm.dataForm.assessmentInfo.insureRate),callback:function ($$v) {_vm.$set(_vm.dataForm.assessmentInfo, "insureRate", $$v)},expression:"dataForm.assessmentInfo.insureRate"}},[_c('div',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])])],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                type: 'input',
                label: '案件责任比例：',
                isNum: true,
                modelTextKey: 'caseLiabilityRate',
              }},on:{"returnVal":_vm.calcamountPayable},model:{value:(_vm.dataForm.assessmentInfo.caseLiabilityRate),callback:function ($$v) {_vm.$set(_vm.dataForm.assessmentInfo, "caseLiabilityRate", $$v)},expression:"dataForm.assessmentInfo.caseLiabilityRate"}},[_c('div',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])])],1),_c('el-form-item',{attrs:{"prop":"assessmentInfo.totalDeductible","rules":{
              required: _vm.lastStatusKey == '7',
              message: '请输入免赔金额',
              trigger: 'change',
            }}},[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                type: 'input',
                label: '免赔金额：',
                required: true,
                isNum: true,
                modelTextKey: 'totalDeductible',
              }},on:{"returnVal":_vm.calcamountPayable},model:{value:(_vm.dataForm.assessmentInfo.totalDeductible),callback:function ($$v) {_vm.$set(_vm.dataForm.assessmentInfo, "totalDeductible", $$v)},expression:"dataForm.assessmentInfo.totalDeductible"}},[_c('div',{staticStyle:{"width":"26px"},attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_c('el-select',{attrs:{"placeholder":""},on:{"change":_vm.calcamountPayable},model:{value:(_vm.dataForm.assessmentInfo.totalDeductibleUnit),callback:function ($$v) {_vm.$set(_vm.dataForm.assessmentInfo, "totalDeductibleUnit", $$v)},expression:"dataForm.assessmentInfo.totalDeductibleUnit"}},_vm._l((_vm.dictList.unit),function(dic){return _c('el-option',{key:dic.value,attrs:{"label":dic.label,"value":dic.value}})}),1)],1)])],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                type: 'radio',
                label: '是否购买不计免赔：',
                option: _vm.dictList.yesNo,
              }},model:{value:(_vm.dataForm.assessmentInfo.buyDeductible),callback:function ($$v) {_vm.$set(_vm.dataForm.assessmentInfo, "buyDeductible", $$v)},expression:"dataForm.assessmentInfo.buyDeductible"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                type: 'input',
                label: '残值合计：',
                disabled: true,
              }},model:{value:(_vm.dataForm.assessmentInfo.salvageSum),callback:function ($$v) {_vm.$set(_vm.dataForm.assessmentInfo, "salvageSum", $$v)},expression:"dataForm.assessmentInfo.salvageSum"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                type: 'input',
                isNum: true,
                label: '增值税合计：',
              }},model:{value:(_vm.dataForm.assessmentInfo.valueAdded),callback:function ($$v) {_vm.$set(_vm.dataForm.assessmentInfo, "valueAdded", $$v)},expression:"dataForm.assessmentInfo.valueAdded"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['7'],"config":{
                type: 'input',
                label: '应赔金额：',
                disabled: true,
              }},model:{value:(_vm.dataForm.assessmentInfo.amountPayable),callback:function ($$v) {_vm.$set(_vm.dataForm.assessmentInfo, "amountPayable", $$v)},expression:"dataForm.assessmentInfo.amountPayable"}})],1)],1)]),_c('AssociationScrollView',{attrs:{"title":"索赔资料","name":"5"}},[(_vm.controlEdit['8'])?_c('template',{slot:"head-right"},[_c('el-button',{staticClass:"base-btn green-btn",attrs:{"icon":"el-icon-circle-plus-outline"},on:{"click":_vm.addPayee}},[_vm._v("添加收款方")])],1):_vm._e(),_c('div',_vm._l((_vm.dataForm.demandInfoList),function(demand,index){return _c('div',[_c('div',{staticClass:"table-layout"},[_c('el-form-item',{attrs:{"prop":"assessmentInfo.insureRate"}},[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['8'],"config":{
                    type: 'radio',
                    label: '用户类型：',
                    option: _vm.dictList.userType,
                  }},model:{value:(demand.payeeType),callback:function ($$v) {_vm.$set(demand, "payeeType", $$v)},expression:"demand.payeeType"}})],1),(demand.payeeType == 1)?[_c('el-form-item',{attrs:{"rules":{
                    required: _vm.lastStatusKey == '8',
                    message: '请输入公司名称',
                    trigger: 'change',
                  },"prop":`demandInfoList.${index}.corporateName`}},[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['8'],"config":{
                      type: 'input',
                      label: '公司名称：',
                      required: true,
                    }},model:{value:(demand.corporateName),callback:function ($$v) {_vm.$set(demand, "corporateName", $$v)},expression:"demand.corporateName"}})],1),_c('el-form-item',{attrs:{"rules":{
                    required: _vm.lastStatusKey == '8',
                    message: '请输入营业执照编号',
                    trigger: 'change',
                  },"prop":`demandInfoList.${index}.businessLicenseNo`}},[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['8'],"config":{
                      type: 'input',
                      label: '营业执照编号：',
                      required: true,
                    }},model:{value:(demand.businessLicenseNo),callback:function ($$v) {_vm.$set(demand, "businessLicenseNo", $$v)},expression:"demand.businessLicenseNo"}})],1)]:_vm._e(),(demand.payeeType == 2)?[_c('el-form-item',{attrs:{"rules":{
                    required: _vm.lastStatusKey == '8',
                    message: '请输入请输入姓名',
                    trigger: 'change',
                  },"prop":`demandInfoList.${index}.name`}},[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['8'],"config":{
                      type: 'input',
                      label: '姓名：',
                      required: true,
                    }},model:{value:(demand.name),callback:function ($$v) {_vm.$set(demand, "name", $$v)},expression:"demand.name"}})],1),_c('el-form-item',{attrs:{"rules":{
                    required: _vm.lastStatusKey == '8',
                    message: '请输入身份证号码',
                    trigger: 'change',
                  },"prop":`demandInfoList.${index}.number`}},[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['8'],"config":{
                      type: 'input',
                      label: '身份证号码：',
                      required: true,
                    }},model:{value:(demand.number),callback:function ($$v) {_vm.$set(demand, "number", $$v)},expression:"demand.number"}})],1)]:_vm._e(),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['8'],"config":{
                    type: 'input',
                    label: '电话：',
                    isNum: true,
                  }},model:{value:(demand.phone),callback:function ($$v) {_vm.$set(demand, "phone", $$v)},expression:"demand.phone"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['8'],"config":{
                    type: 'input',
                    label: '地址：',
                  }},model:{value:(demand.address),callback:function ($$v) {_vm.$set(demand, "address", $$v)},expression:"demand.address"}})],1),_c('el-form-item',{attrs:{"rules":{
                  required: _vm.lastStatusKey == '8',
                  message: '请输入账号',
                  trigger: 'change',
                },"prop":`demandInfoList.${index}.bankNumber`}},[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['8'],"config":{
                    type: 'input',
                    label: '账号：',
                    isNum: true,
                    required: true,
                  }},model:{value:(demand.bankNumber),callback:function ($$v) {_vm.$set(demand, "bankNumber", $$v)},expression:"demand.bankNumber"}})],1),_c('el-form-item',{attrs:{"rules":{
                  required: _vm.lastStatusKey == '8',
                  message: '请输入开户行',
                  trigger: 'change',
                },"prop":`demandInfoList.${index}.bankOfDeposit`}},[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['8'],"config":{
                    type: 'input',
                    label: '开户行：',
                    required: true,
                  }},model:{value:(demand.bankOfDeposit),callback:function ($$v) {_vm.$set(demand, "bankOfDeposit", $$v)},expression:"demand.bankOfDeposit"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.controlEdit['8'],"config":{
                    type: 'input',
                    label: '赔付金额：',
                    isNum: true,
                  }},model:{value:(demand.amountPaid),callback:function ($$v) {_vm.$set(demand, "amountPaid", $$v)},expression:"demand.amountPaid"}})],1)],2),_c('div',{staticStyle:{"margin-top":"24px","display":"flex","justify-content":"flex-end"}},[(_vm.controlEdit['8'])?_c('el-button',{staticClass:"base-btn",staticStyle:{"background-color":"#f56c6c"},attrs:{"icon":"el-icon-delete"},on:{"click":function($event){return _vm.delItem(_vm.dataForm.demandInfoList, index)}}},[_vm._v("删除")]):_vm._e()],1)])}),0)],2)],1)],1),_c('div',{staticClass:"action-footer"},[_c('el-button',{staticClass:"base-btn white-btn",on:{"click":_vm.back}},[_vm._v("返回")]),_c('el-button',{staticClass:"base-btn white-btn",on:{"click":_vm.viewFile}},[_vm._v("查看附件")]),_c('el-button',{staticClass:"base-btn",staticStyle:{"background-color":"#4278c9"},attrs:{"icon":"el-icon-circle-check"},on:{"click":_vm.submit}},[_vm._v("保存")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }